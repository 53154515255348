<template>
  <div class="auth">
    <div class="auth__side auth__content">
      <div class="auth__logo">
          <UplifyLogo />
      </div>

      <div class="auth__inner">
        <router-view />
      </div>

      <div class="auth__footer text-s-regular">
        <LocaleSwitcher />

        <div
          v-show="showAbout"
          class="auth__footer-links"
        >
          <a :href="$t('links.terms')">{{ locale.phrases.terms }}</a>
          <a :href="$t('links.privacy')">{{ locale.phrases.privacy }}</a>
          <a :href="$t('links.discord')">{{ locale.phrases.help }}</a>
        </div>

        <router-link
          v-show="showAbout"
          :to="{name: 'auth-partner-signin'}"
          class="auth__partner-link"
        >
          {{ locale.partnerLogin }}
        </router-link>
      </div>
    </div>

    <div
      v-show="showAbout"
      class="auth__side"
    >
      <AuthInfo />
    </div>
  </div>
</template>

<script>
import LocaleSwitcher from 'components/LocaleSwitcher'
import UplifyLogo from 'components/UplifyLogo'
import AuthInfo from 'views/Auth/components/AuthInfo'
import { router } from 'views/Auth/router'

export default {
  router,

  components: {
    LocaleSwitcher,
    UplifyLogo,
    AuthInfo,
  },

  data: () => ({
    dialogVisible: false,
  }),

  computed: {
    showAbout: ({ $route }) => (/(?!.*auth-streamer-settings)^auth-streamer|referral-token|partner-referral-token$/).test($route.name),
    locale: ({ $locale, $rootLocale }) => ({
      partnerLogin: $locale('partnerLogin'),
      phrases: $rootLocale('phrases'),
    }),
    error: ({ $route }) => $route.query.error,
  },
}
</script>

<style lang="scss" scoped>
.auth {
  display: flex;
  width: 100%;
  min-height: 100vh;
  position: relative;

  &__side {
    width: 100%;
    flex-grow: 1;
    position: relative;
  }

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;

    @media only screen and (max-height: 800px) {
      padding-top: 100px;
    }

    @media (max-width: $--tablet-portrait) {
      padding: 110px 0;
    }

    @media (max-width: $--large-mobile) {
      flex-direction: column;
      padding: 130px 0 120px;
    }
  }

  &__logo {
    position: absolute;
    top: 35px;
    left: 24px;

    @media (max-width: $--large-mobile) {
      left: 20px;
    }

    svg {
      fill: $--color-primary;
    }
  }

  &__inner {
    width: 320px;

    @media (max-width: $--large-mobile) {
      width: calc(100% - 40px);
      max-width: 420px;
    }
  }

  &__footer {
    position: absolute;
    left: 15px;
    bottom: 8px;
    display: flex;
    align-items: center;
  }

  &__footer-links {
    display: flex;
    align-items: center;

    a {
      font-size: 12px;
      line-height: 14px;

      &:not(:last-child) {
        margin-right: 24px;
      }
    }
  }

  &__partner-link {
    margin-left: 24px;

    @media (max-width: $--large-mobile) {
      position: absolute;
      bottom: calc(100% + 16px);
      left: 7px;
      margin: 0;
    }
  }
}
</style>
