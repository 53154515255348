export default [
  {
    path: '/',
    redirect: { name: 'auth' },
  },
  {
    path: '/ref/:token',
    name: 'referral-token',
    component: () => import('views/Auth/AuthStreamer.vue'),
    meta: { locale: 'streamerSignin', title: 'Creator Log In - Uplify' },
  },
  {
    path: '/partner/ref/:token',
    name: 'partner-referral-token',
    component: () => import('views/Auth/AuthStreamer.vue'),
    meta: { locale: 'streamerSignin', title: 'Creator Log In - Uplify' },
  },
  {
    path: '/auth',
    name: 'auth',
    redirect: { name: 'auth-streamer' },
    component: { template: '<router-view />' },
    children: [
      {
        path: 'streamer',
        name: 'auth-streamer',
        component: () => import('views/Auth/AuthStreamer.vue'),
        meta: { locale: 'streamerSignin', title: 'Creator Log In - Uplify' },
      },
      {
        path: 'streamer/settings',
        name: 'auth-streamer-settings',
        component: () => import('views/Auth/AuthStreamerSettings.vue'),
        meta: { locale: 'streamerSettings', title: 'Creator Setup Log In - Uplify' },
      },

      {
        path: 'partner',
        name: 'auth-partner-signin',
        component: () => import('views/Auth/AuthPartnerSignin.vue'),
        meta: { locale: 'partnerSignin', title: 'Partner Log In - Uplify' },
      },
      {
        path: 'partner/request',
        name: 'auth-partner-request',
        component: () => import('views/Auth/AuthPartnerRequest.vue'),
        meta: { locale: 'partnerRequest', title: 'Partner Request - Uplify' },
      },

      {
        path: 'partner/password/restore',
        name: 'auth-reset-password',
        component: () => import('views/Auth/AuthResetPassword.vue'),
        meta: { locale: 'resetPassword', title: 'Password Recovery - Uplify' },
      },
      {
        path: 'partner/password/new',
        name: 'auth-new-password',
        component: () => import('views/Auth/AuthNewPassword.vue'),
        meta: { locale: 'newPassword', title: 'New Password - Uplify' },
      },
    ],
  },
  {
    path: '*',
    redirect: { name: 'auth' },
  },
]
