interface Referral {
  createdAt: number
  value: string
}

const REFERRAL = 'referral'

const checkExpired = (referral: Referral): boolean => {
  const { createdAt } = referral
  const week = 1000 * 60 * 60 * 24 * 7 // 7 days
  const passedTime = new Date().getTime() - createdAt

  return passedTime > week
}

const removeToken = () => {
  localStorage.removeItem(REFERRAL)
}

const setReferral = (value: string, partnerId: string) => {
  localStorage.setItem(REFERRAL, JSON.stringify({
    createdAt: new Date().getTime(),
    value,
    ...(partnerId ? { partnerId } : {}),
  }))
}

export const getReferral = () => {
  const referralStr = localStorage.getItem(REFERRAL)
  if (!referralStr) return null

  const referral = JSON.parse(referralStr) as Referral
  if (checkExpired(referral)) {
    removeToken()
    return null
  }

  return referral
}

export default (token: string, partnerId: string) => {
  const referral = getReferral()
  if (referral) return

  setReferral(token, partnerId)
}
