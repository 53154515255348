import { Route } from "vue-router"

interface Query {
  [propName: string]: string
}

export default (query: Route['query']): void => {
  let params = {} as Route['query']

  // if (typeof query === 'string') {
  //   params = query
  // }

  Object.keys(query).forEach(key => {
    if (key === 'promo') {
      params[key] = query[key]
    }

    if (key.includes('utm_')) {
      params[key] = query[key]
    }

    localStorage.setItem('registration-params', JSON.stringify(params))
  })
}
