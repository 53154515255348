<template>
  <AuthLogin />
</template>

<script>
import checkReferral from 'Auth/helpers/checkReferral'
import setRegistrationParams from 'Auth/helpers/setRegistrationParams'
import AuthLogin from 'Auth/templates/AuthLogin'
import { router } from 'views/Auth/router'

export default {
  router,

  components: {
    AuthLogin,
  },

  async mounted () {
    await this.setRegistrationParams()
    if ((/^referral-token|partner-referral-token$/).test(this.$route.name)) {
      this.checkReferralToken()
    }
    else {
      this.checkStreamerFilling()
    }
  },

  methods: {
    setRegistrationParams () {
      return new Promise(resolve => {
        // setTimeout нужен, т.к. роут у дочерних компонентов
        // заполняется в процессе рендеринга и не доступен сразу
        // (nextTick не отрабатывает этот случай)
        setTimeout(() => {
          if (Object.keys(this.$route.query).length) {
            setRegistrationParams(this.$route.query)
          }
          resolve()
        }, 100)
      })
    },
    checkReferralToken () {
      checkReferral(this.$route.params.token, this.$route.query.utm_campaign)
      this.$router.push({ name: 'auth-streamer' })
    },
    checkStreamerFilling () {
      const { $router, $route, $store } = this
      const isNotFilled = $store.state.auth.user && !$store.getters['auth/isFilled']
      const isNotSettings = $route.name !== 'auth-streamer-settings'

      if (isNotFilled && isNotSettings) {
        $router.replace({ name: 'auth-streamer-settings' })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.auth {
  display: flex;
  width: 100%;
  min-height: 100vh;
  position: relative;

  @media (max-width: $--tablet-portrait) {
    flex-direction: column;
  }

  &--shifted {
    padding-top: 48px;
  }

  &__side {
    width: 100%;
    flex-grow: 1;
    position: relative;
  }

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;

    @media only screen and (max-height: 800px) {
      padding-top: 100px;
    }
  }

  &__logo {
    position: absolute;
    top: 35px;
    left: 24px;

    svg {
      fill: $--color-primary;
    }
  }

  &__footer {
    position: absolute;
    left: 15px;
    bottom: 8px;
  }
}
</style>
