<template>
  <div class="auth-info">
    <div class="auth-info__wrap">
      <div class="auth-info__block _1">
        <h2 v-html="locale.info.block1.title" />
        <TextLink
          :href="locale.info.block1.linkHref"
          target="_blank"
        >
          {{ locale.info.block1.linkText }}
        </TextLink>
        <button
          class="auth-info__video-trigger"
          @click="dialogVisible = true"
        >
          <SvgIcon
            class="auth-info__play-btn"
            name="circle-video-btn"
          />
        </button>
      </div>

      <div class="auth-info__block _2">
        <h2 v-html="locale.info.block2.title" />
        <TextLink
          :href="locale.info.block2.linkHref"
          target="_blank"
        >
          {{ locale.info.block2.linkText }}
        </TextLink>
      </div>

      <div class="auth-info__block _3">
        <h2 v-html="locale.info.block3.title" />
        <TextLink
          :href="locale.info.block3.linkHref"
          target="_blank"
        >
          {{ locale.info.block3.linkText }}
        </TextLink>
      </div>
    </div>

    <ElDialog
      :visible.sync="dialogVisible"
    >
      <div class="auth-info__video">
        <iframe
          v-if="dialogVisible"
          src="https://www.youtube-nocookie.com/embed/YwVVjHJ_Ii0?rel=0&controls=0&showinfo=0&autoplay=1&modestbranding=1&version=3&hl=en_US&autohide=1"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; gyroscope"
          allowfullscreen
        />
      </div>
    </ElDialog>
  </div>
</template>

<script>
import TextLink from 'components/buttons/TextLink'
import ElDialog from 'element/ElDialog'
import { router } from 'views/Auth/router'

export default {
  router,

  components: {
    TextLink,
    ElDialog,
  },

  data: () => ({
    dialogVisible: false,
  }),

  computed: {
    locale: ({ $rootLocale }) => ({
      info: $rootLocale('views.auth.streamerSignin.info'),
    }),
    error: ({ $route }) => $route.query.error,
  },
}
</script>

<style lang="scss" scoped>
.auth-info {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 24px;
  background-color: $--color-primary-light-8;

  @media (max-width: $--tablet-portrait) {
    padding: 40px 24px 100px;
  }

  @media (max-width: $--large-mobile) {
    flex-direction: column;
    padding: 20px 20px 60px;
  }

  :deep {
    .el-dialog {
      width: 688px;

      @media (max-width: $--large-mobile) {
        width: calc(100% - 40px);
      }
    }
  }

  &__wrap {
    width: 100%;
    max-width: 672px;

    @media (max-width: $--tablet-portrait) {
      max-width: 100%;
    }
  }

  &__play-btn {
    ::v-deep {
      path[pid="0"] {
        fill: $--color-primary;
      }
      path[pid="1"] {
        fill: $--color-white;
      }
    }
  }

  &__block {
    position: relative;
    width: 100%;
    padding-right: 80px;

    @media (max-width: $--large-mobile) {
      padding-right: 0;
    }

    &:not(:last-of-type) {
      padding-bottom: 32px;
      margin-bottom: 32px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);

      @media (max-width: $--large-mobile) {
        margin-bottom: 16px;
        padding-bottom: 40px;
      }
    }

    &._1 {
      padding-right: 120px;

      @media (max-width: $--large-mobile) {
        padding-top: 96px;
        padding-right: 0;
      }
    }

    h2 {
      margin-bottom: 16px;
      font-size: 32px;
      font-weight: 400;
      line-height: 1;

      @media (max-width: $--large-mobile) {
        font-size: 24px;
      }

      ::v-deep {
        strong {
          font-size: inherit;
          font-weight: 700;
          line-height: inherit;
          color: $--color-info;
        }
      }
    }

    ::v-deep {
      .text-link svg {
        transform: translateY(3px);
      }
    }
  }

  &__video {
    position: relative;

    &::after {
      display: block;
      content: "";
      padding-top: 56.25%;
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__video-trigger {
    position: absolute;
    right: 0;
    bottom: 32px;
    width: 96px;
    height: 96px;
    background-color: transparent;
    border: none;

    @media (max-width: $--large-mobile) {
      width: 80px;
      height: 80px;
      right: auto;
      bottom: auto;
      left: 0;
      top: 0;
    }
  }

  &__links {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 25px;
    padding: 0 15px;

    @media (max-width: $--large-mobile) {
      display: block;
      position: static;
      margin-top: 80px;
      padding: 0;
    }

    &-items {
      white-space: nowrap;

      @media (max-width: $--large-mobile) {
        display: grid;
        row-gap: 16px;
      }

      &:nth-child(1),
      &:nth-child(3){
        width: 25%;

        @media (max-width: $--large-mobile) {
          width: 100%;
        }
      }

      &:nth-child(2) {
        text-align: center;
        width: 50%;

        @media (max-width: $--large-mobile) {
          width: 100%;
          text-align: left;
        }
      }

      &:nth-child(3) {
        text-align: right;

        @media (max-width: $--large-mobile) {
          text-align: left;
        }
      }
    }

    a {
      display: inline-block;
      margin: 0 12px;

      @media (max-width: $--large-mobile) {
        margin: 0;
        font-size: 12px;
        line-height: 14px;
        font-weight: 400;
      }

      &:after {
        display: none;
      }
    }
  }
}
</style>
